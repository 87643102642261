//
// Jumbotron
// --------------------------------------------------


.jumbotron {
  padding: @jumbotron-padding;
  margin-bottom: @jumbotron-padding;
  color: @jumbotron-color;
  background-color: @jumbotron-bg;
  
  // sicosf
  padding-top: @jumbotron-padding-top;
  padding-bottom: @jumbotron-padding-bottom;
  
  -webkit-box-shadow: @sombreado-generico;
  -moz-box-shadow: @sombreado-generico;
  box-shadow: @sombreado-generico;
  // fin sicosf

  h1, .h1, h2, .h2, h3, .h3 {
    color: @jumbotron-heading-color;
    font-family: @jumbotron-font-family;
  }

  h1, .h1, h2, .h2, h3, .h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    text-align: left;
  }

  p {
    margin-bottom: (@jumbotron-padding / 2);
    font-size: @jumbotron-font-size;
    font-weight: 200;
  }

  > hr {
    border-top-color: darken(@jumbotron-bg, 10%);
  }

  .container & {
    border-radius: @border-radius-large; // Only round corners at higher resolutions if contained in a container
  }

  .container {
    max-width: 100%;
  }

  @media screen and (min-width: @screen-sm-min) {
    padding-top:    (@jumbotron-padding * 1.2);
    padding-bottom: (@jumbotron-padding * 1.2);

    .container & {
      padding-left:  (@jumbotron-padding * 2);
      padding-right: (@jumbotron-padding * 2);
    }

    h1,
    .h1 {
      font-size: (@font-size-base * 4.5);
    }
  }

  // Esto siguiente hace que el jumbotron se oculte en pantallas peque�as porque ocupa mucho sitio
  @media screen and (max-width: @screen-sm-min) {
    display: none;
  }
}
