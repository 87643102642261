.footer {
	position: absolute;
	bottom: 0;
	width: 100%;
	/* Set the fixed height of the footer here */
	height: @footer-height;
	color: @footer-color;
	background-color: @footer-bg-color; 
	-webkit-box-shadow: @footer-shadow;
	-moz-box-shadow: @footer-shadow;
	box-shadow: @footer-shadow;
}
/** para bajar el contenido con una barra de navegacioni fija */
html {
  position: relative;
  min-height: 100%;
}
body > .container {
  padding-top: @footer-padding-top;
  padding-bottom: @footer-padding-bottom;
}
